import React, { useState } from "react";
import { animateScroll as scroll } from "react-scroll";
import { FaArrowCircleUp } from "react-icons/fa";

const ScrollArrow = () => {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollToTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  window.addEventListener("scroll", checkScrollToTop);
  return (
    <FaArrowCircleUp
      className="scrollToTop fixed bottom-20 right-10 h-12 w-12 fill-current text-indigo-400 hover:text-green-400 cursor-pointer"
      onClick={scrollToTop}
      style={{ display: showScroll ? "flex" : "none" }}
    />
  );
};

export default ScrollArrow;
