import React from "react";
import Cloud from "./Cloud";

export default function Loading() {
  return (
    <div className="h-full w-full flex justify-center animate-pulse">
      <Cloud className="w-96 h-96 stroke-current  text-indigo-500 animate-pulse" />
      <h1 className="text-4xl absolute top-56 text-green-400 animate-pulse">
        Loading...
      </h1>
    </div>
  );
}
