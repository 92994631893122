import React, { useState, useEffect } from "react";
import sanityClient from "../client.js";
import Loading from "./Loading";

export default function Project() {
  const [projectData, setProjectData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "project"]{
          title,
          date,
          place,
          description,
          projectType,
          link,
          sourceCode,
          tags,
        }`
      )
      .then((data) => setProjectData(data))
      .catch(console.error);
  }, []);
  if (!projectData) return <Loading />;
  return (
    <main>
      <header className="w-screen">
        <section className="text-center flex flex-col pt-12 lg:pt-64 px-8 ">
          <h1 className="text-2xl md:text-6xl text-green-300 font-bold cursive leading-none md:leading-snug mb-4">
            Projects
          </h1>
          <p className="text-lg md:text-4xl text-indigo-400">
            Check out some of my work!
          </p>
        </section>
      </header>
      <section className="container mx-auto pt-12 lg:pt-64 px-8 grid md:grid-cols-2 lg:grid-cols-3 gap-8">
        {projectData &&
          projectData.map((project, index) => (
            <article className="hover:border-green-400 group relative rounded-lg shadow-xl bg-gray-800 p-8 md:p-8 border-2 border-indigo-500 transition duration-500 ease-in-out transform hover:rotate-2">
              <h3 className="text-indigo-400 text-3xl font-bold mb-2">
                <a
                  href={project.link}
                  alt={project.title}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="group-hover:text-green-400"
                >
                  {project.title}
                </a>
              </h3>
              <div className="text-indigo-400 text-xs space-x-4">
                <span>
                  <strong className="font-bold">Finished on</strong>:{" "}
                  {new Date(project.date).toLocaleDateString()}
                </span>
                <span>
                  <strong className="font-bold">Company</strong> {project.place}
                </span>
                <span>
                  <strong className="font-bold">Type</strong>:{" "}
                  {project.projectType}
                </span>
                <p className="my-6 text-lg text-gray-400 leading-relaxed">
                  {project.description}
                </p>
                <div className="text-center flex flex-nowrap justify-between">
                  <a
                    href={project.link}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="m-2 p-2"
                  >
                    👉{" "}
                    <span
                      className="text-green-400 font-bold hover:underline hover:text-green-300"
                      role="img"
                      arial-label="right pointer"
                    >
                      View the Project
                    </span>
                  </a>
                  <a
                    href={project.sourceCode}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="m-2 p-2"
                  >
                    💾{" "}
                    <span
                      className="text-green-400 font-bold hover:underline hover:text-green-300 "
                      role="img"
                      arial-label="floppy disk"
                    >
                      Souce Code
                    </span>
                  </a>
                </div>
              </div>
            </article>
          ))}
      </section>
    </main>
  );
}
