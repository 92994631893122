import React from "react";
import Landing from "./Landing";
import About from "./About";
import Contact from "./Contact";

export default function Home() {
  return (
    <main>
      <Landing />
      <About />
      <Contact />
    </main>
  );
}
