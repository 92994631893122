import React from "react";
import { SocialIcon } from "react-social-icons";

export default function Footer() {
  return (
    <footer className="bg-gray-900 w-full flex flex-row justify-between bottom-0 z-50">
      <div className="flex flex-grow justify-center">
        <p className="text-xs md:text-md self-center text-green-400">
          &copy; 2021 Oroshi Web Development
        </p>
      </div>
      {/* Social Icons*/}
      <div className="flex flex-row mx-4">
        <SocialIcon
          url="https://www.linkedin.com/in/thomas-llewellyn"
          className="m-2 md:mr-4"
          fgColor="#fff"
          target="_blank"
          style={{ height: 35, width: 35 }}
        />
        <SocialIcon
          url="https://github.com/oroshi-tom"
          className="m-2 md:mr-4"
          fgColor="#fff"
          target="_blank"
          style={{ height: 35, width: 35 }}
        />
        <SocialIcon
          url="https://twitter.com/oroshi_tom"
          className="m-2 md:mr-4"
          fgColor="#fff"
          target="_blank"
          style={{ height: 35, width: 35 }}
        />
      </div>
      <div data-netlify-nocaptcha="true"></div>
    </footer>
  );
}
