import React from "react";
import NavBar from "./NavBar";

export default function Header() {
  return (
    <header className="bg-gray-900 w-full fixed top-0 z-50 opacity-95">
      <NavBar />
    </header>
  );
}
