import React from "react";
import Header from "./Header";
import Footer from "./Footer";
// import CloudBackground from "./CloudBackground";
import ScrollArrow from "./ScrollArrow";

export default function Layout(props) {
  return (
    <div className="hero-pattern min-h-screen min-w-full bg-fixed relative overflow-hidden flex flex-col justify-between">
      {/* <CloudBackground /> */}
      <Header />
      <div className="relative mb-8">
        <div className="mt-14 z-50">{props.children}</div>
      </div>
      <Footer />
      <ScrollArrow />
    </div>
  );
}
