import React from "react";

export default function Landing() {
  return (
    <div className="w-screen">
      <section className="text-center flex flex-col pt-12 lg:pt-64 px-8 ">
        <h1 className="text-2xl md:text-6xl text-green-300 font-bold cursive leading-none md:leading-snug mb-4">
          Oroshi Web Tech
        </h1>
        <p className="text-lg md:text-4xl text-indigo-400">
          The future's in the air
        </p>
      </section>
    </div>
  );
}
