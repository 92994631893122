import React from "react";
import ContactForm from "./ContactForm";

export default function Contact() {
  return (
    <section className="w-screen">
      <div className="p-10 lg:pt-48 mx-auto container">
        <div className="bg-gray-800 rounded-lg shadow-2xl flex flex-col p-4 md:p-20 border-2 border-indigo-500">
          <div className="text-center">
            <h1 className="cursive text-3xl md:text-4xl lg:text-6xl text-green-300 mb-4">
              Get in touch
            </h1>
            <p className="text-indigo-500">
              Send me a message with your next big idea!
            </p>
          </div>
          <ContactForm />
        </div>
      </div>
    </section>
  );
}
