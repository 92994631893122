import { BrowserRouter, Route, Switch } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/Home";
import Project from "./components/Project";
import SinglePost from "./components/SinglePost";
import Post from "./components/AllPosts";

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          <Route component={Home} exact path="/" />
          <Route component={Project} path="/project" />
          <Route component={SinglePost} path="/post/:slug" />
          <Route component={Post} path="/post" />
        </Switch>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
