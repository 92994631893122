import React from "react";
import { NavLink } from "react-router-dom";
import { SocialIcon } from "react-social-icons";
import logo from "../img/logo.png";

export default function NavBar() {
  const [isOpen, setIsOpen] = React.useState(false);

  function handleClick() {
    setIsOpen(!isOpen);
  }
  return (
    /* Large Screen Nav Menu */
    <nav className="w-full p-4 flex flex-row justify-between">
      {/* Lage Screen Logo */}
      <div className="order-1 z-10">
        <NavLink
          exact
          to="/"
          className="w-32 md:w-40 place-self-center object-scale-down flex items-center flex-shrink-0"
        >
          <img src={logo} alt="logo"></img>
        </NavLink>
      </div>
      {/* Menu Button */}
      <div className="order-5 z-10">
        <button
          type="button"
          className="block md:hidden py-2 px-1 z-10 fill-current text-green-500"
          onClick={handleClick}
        >
          <svg
            className="h-6 w-6 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            {isOpen && (
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
              />
            )}
            {!isOpen && (
              <path
                fillRule="evenodd"
                d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
              />
            )}
          </svg>
        </button>
      </div>
      {/* Large Screen Nav Menu */}
      <div className="hidden md:flex md:flex-row flex-shrink-0 text-left order-2 md:order-2 items-end">
        <NavLink
          exact
          to="/"
          className="flex-shrink-0 mx-3 py-2 text-sm text-indigo-300 hover:text-green-400 tracking-widest lg:text-2xl md:text-xl border-b-2 border-gray-900"
          activeClassName=" border-green-400"
        >
          Home
        </NavLink>

        <NavLink
          to="/project"
          className="flex-shrink-0 mx-3 py-2 text-sm text-indigo-300 hover:text-green-400 tracking-widest lg:text-2xl md:text-xl border-b-2 border-gray-900"
          activeClassName="border-b-2 border-green-400"
        >
          Projects
        </NavLink>
        <NavLink
          to="/post"
          className="flex-shrink-0 mx-3 py-2 text-sm text-indigo-300 hover:text-green-400 tracking-widest lg:text-2xl md:text-xl border-b-2 border-gray-900"
          activeClassName="border-b-2 border-green-400"
        >
          Blog
        </NavLink>
      </div>
      {/* Small Screen Nav Menu */}
      <div className={`md:hidden ${isOpen ? "block" : "hidden"} `}>
        <div className="h-screen w-screen absolute z-0  flex flex-col justify-center items-center bg-gray-900 text-4xl">
          <NavLink
            exact
            to="/"
            className="flex-shrink-0 text-4xl mx-3 py-2 text-sm text-indigo-300 hover:text-green-400 tracking-widest border-b-2 border-gray-900"
            activeClassName=" border-green-400"
            onClick={handleClick}
          >
            Home
          </NavLink>

          <NavLink
            to="/project"
            className="flex-shrink-0 text-4xl mx-3 py-2 text-sm text-indigo-300 hover:text-green-400 tracking-widest border-b-2 border-gray-900"
            activeClassName="border-b-2 border-green-400"
            onClick={handleClick}
          >
            Projects
          </NavLink>
          <NavLink
            to="/post"
            className="flex-shrink-0 text-4xl mx-3 py-2 text-sm text-indigo-300 hover:text-green-400 tracking-widest border-b-2 border-gray-900"
            activeClassName="border-b-2 border-green-400"
            onClick={handleClick}
          >
            Blog
          </NavLink>
        </div>
      </div>

      {/* Social Icons*/}
      <div className="hidden md:order-3 md:flex mx-4 md:flex-row">
        <SocialIcon
          url="https://www.linkedin.com/in/thomas-llewellyn"
          className="my-2 md:mr-4"
          fgColor="#fff"
          target="_blank"
          style={{ height: 35, width: 35 }}
        />
        <SocialIcon
          url="https://github.com/oroshi-tom"
          className="my-2 md:mr-4"
          fgColor="#fff"
          target="_blank"
          style={{ height: 35, width: 35 }}
        />
        <SocialIcon
          url="https://twitter.com/oroshi_tom"
          className="my-2 md:mr-4"
          fgColor="#fff"
          target="_blank"
          style={{ height: 35, width: 35 }}
        />
      </div>
    </nav>
  );
}
