import React from "react";

export default function ContactForm(props) {
  return (
    <div className="mt-4 md:grid md:grid-cols-3 md:gap-6">
      <div className="md:col-span-3">
        <form
          name="contact"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="contact" />
          <div class="overflow-hidden sm:rounded-md">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <label className="block text-indigo-400" htmlFor="firstname">
                  First Name
                </label>
                <input
                  className="block w-full rounded-md  p-2 focus:outline-none focus:ring-2 focus:ring-green-400 focus:border-transparent"
                  type="text"
                  id="firstname"
                  name="firstname"
                  variant="filled"
                  required
                  placeholder="Name..."
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label className="block text-indigo-400" htmlFor="lastname">
                  Last Name
                </label>
                <input
                  className="block w-full rounded-md  p-2 focus:outline-none focus:ring-2 focus:ring-green-400 focus:border-transparent"
                  type="text"
                  id="lastname"
                  name="lastname"
                  variant="filled"
                  required
                  placeholder="Name..."
                />
              </div>
              <div className="col-span-6 lg:col-span-4">
                <label className="block text-indigo-400" htmlFor="email">
                  Email
                </label>
                <input
                  className="block w-full rounded-md  p-2 focus:outline-none focus:ring-2 focus:ring-green-400 focus:border-transparent"
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Email..."
                  required
                />
              </div>
              <div className="col-span-6 ">
                <label className="block text-indigo-400" htmlFor="message">
                  Message
                </label>
                <textarea
                  className="block w-full rounded-md  p-2 focus:outline-none focus:ring-2 focus:ring-green-400 focus:border-transparent"
                  id="message"
                  name="message"
                  placeholder="Message..."
                  required
                ></textarea>
              </div>
              <div data-netlify-recaptcha="true"></div>
            </div>
            <div className="mt-2 md:mt-8">
              <button
                className="rounded px-4 py-2 bg-green-400 hover:bg-green-500 focus:outline-none focus:ring-4 focus:ring-green-400 focus:ring-opacity-50 focus:ring-offset-2 focus:ring-offset-green-400 focus:border-transparent"
                type="submit"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
