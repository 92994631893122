import React, { useState, useEffect } from "react";
import sanityClient from "../client.js";
import { Link } from "react-router-dom";

export default function Post() {
  const [postData, setPost] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"]{
      title,
      slug,
      mainImage{
        asset->{
          _id,
          url
        },
        alt
      }
    }`
      )
      .then((data) => setPost(data))
      .catch(console.error);
  }, []);
  return (
    <main>
      <header className="w-screen">
        <section className="text-center flex flex-col pt-12 lg:pt-64 px-8 ">
          <h1 className="text-2xl md:text-6xl text-green-300 font-bold cursive leading-none md:leading-snug mb-4">
            Oroshi Blog
          </h1>
          <p className="text-lg md:text-4xl text-indigo-400">
            Stuff I think About
          </p>
        </section>
      </header>
      <section className="container mx-auto pt-12 lg:pt-64 px-8 grid md:grid-cols-2 lg:grid-cols-3 gap-8">
        {postData &&
          postData.map((post, index) => (
            <article className="">
              <Link to={"/post/" + post.slug.current} key={post.slug.current}>
                <span
                  className="group border-2 border-indigo-400 hover:border-green-400 rounded block h-64 relative  shadow leading-snug transition duration-500 ease-in-out transform hover:rotate-2"
                  key={index}
                >
                  <img
                    src={post.mainImage.asset.url}
                    alt={post.mainImage.alt}
                    className="w-full h-full rounded object-cover absolute"
                  />
                  <span className="block relative flex h-full justify-center text-center items-end px-4 pb-4">
                    <h3 className="group-hover:bg-green-400 group-hover:bg-opacity-75 group-hover:text-gray-800 text-sm font-bold px-3 py-4 bg-indigo-700 text-indigo-400 bg-opacity-75 rounded">
                      {post.title}
                    </h3>
                  </span>
                </span>
              </Link>
            </article>
          ))}
      </section>
    </main>
  );
}
